export default `# INFORMATIONS LÉGALES

## Plombiers Toulousains.Fr

## Editeur 
HESTIA INNOVATIONS  
SAS au capital de 15 000,00euros  
Immatriculée au RCS de Toulouse sous le n° 848 601 779  
Dont le siège social est situé 2 rue d'Austerlitz, 31000 Toulouse, France  
Téléphone : 05 31 60 05 01     
Pour nous contacter : contact@plombierstoulousains.fr

## Directeur de la publication
Maxime Pawlak, Président

## Hébergeur
Firebase  
1600 Amphitheatre Parkway, Mountain View, California 94043, USA  
https://firebase.google.com/.

## PROPRIETÉ INTELLECTUELLE

HESTIA INNOVATIONS est le propriétaire exclusif de tous les droits de propriété intellectuelle portant tant sur la structure que sur le contenu du Site www.plombierstoulousains.fr.  

Les contenus du site www.plombierstoulousains.fr sont destinés à usage strictement personnel, privé et non collectif, librement pour le contenu gratuit.  

Toute utilisation dans un cadre professionnel ou commercial ou toute commercialisation de ce contenu auprès de tiers est interdite, sauf accord exprès de la Société HESTIA INNOVATIONS à demander à l’adresse suivante : contact@plombierstoulousains.fr .
`;
